<template>
  <v-row class="d-flex justify-center">
    <v-col cols="12" md="8" class="pa-0">
      <v-card
        class="mx-xs-0 mx-sm-auto px-xs-0 px-md-4 py-4"
        max-width="100%"
        outlined
      >
        <CardToolbar
          :disableAdd="loading"
          :disableRefresh="loading"
          :icon="MORTGAGE_DEAL_ICON"
          @add-data="openDialog()"
          @refresh-data="refreshEntries()"
          button-mode
          include-add
          include-help
          include-refresh
          title="Mortgage Deals"
        />
        <v-card-text class="mt-n4">
          <p :class="`${DEFAULT_TEXT}`">A listing of all mortgage deals.</p>
        </v-card-text>

        <HelpText :page="MORTGAGE_DEALS_VUE" />

        <v-data-table
          id="mortgage-deal-table"
          :footer-props="{ 'items-per-page-options': [10, 25, 50, -1] }"
          :headers="headers"
          :height="500"
          :items-per-page="25"
          :items="mortgageDeals"
          :loading="loading"
          :search="search"
          @click:row="viewMortgageDeal"
          class="mx-4"
          fixed-header
          loading-text="Loading, please wait..."
          no-data-text="Table contains no data."
          no-results-text="No results found."
          sort-by="id"
        >
          <template v-slot:top>
            <v-row dense>
              <v-col class="offset-sm-2 col-sm-8 offset-md-3 col-md-6 mb-4" cols="12">
                <v-text-field
                  v-model="search"
                  :prepend-icon="SEARCH_ICON"
                  clearable
                  hide-details
                  label="Search"
                  single-line
                ></v-text-field>
              </v-col>
            </v-row>
          </template>
        </v-data-table>
      </v-card>

      <dialog-crud
        :dialog.sync="dialog.show"
        @save-data="createCompleteMortgageDeal(dialog.payload)"
        :config="dialog.config"
        :payload="dialog.payload"
        :saving="dialog.saving"
      />
    </v-col>
  </v-row>
</template>

<script>
import { cloneDeep, flatten, } from 'lodash'
import { mapActions, mapGetters, } from 'vuex'

import MortgageDeal, { setSelectValidData, TYPE, PROPERTY, } from '../models/dto/MortgageDeal'
import { ICONS, VUES, ROUTES, TEXT_COLOR, VALID_TABLES, } from '../constants'
import { appendUUID, now, transformError, } from '../services/utility.service'
import { push, } from '../router'

export default {
  components: {
    CardToolbar: () => import('../components/layout/toolbars/CardToolbar.vue'),
    DialogCrud: () => import('../components/layout/dialog/DialogCrud.vue'),
    HelpText: () => import('../components/layout/HelpText.vue'),
  },
  data: () => ({
    mortgageDeal: new MortgageDeal(),
    mortgageDeals: [],
    headers: [
      { text: 'ID', align: 'right', value: 'mortgage_deal_id', },
      { text: 'Loan Code', align: 'left', value: 'loan_code' },
    ],
    loading: false,
    search: null,

    // Dialog window state.
    dialog: { show: false, config: {}, payload: {}, saving: false, },
  }),
  computed: {
    ...mapGetters({
      getValidData: 'valid/getData',
      user: 'user/getUser',
    }),
    /**
     * Metadata required by API calls.
     */
    apiCallMetadata () {
      return { operator_id: this.user.id, dlc: now(), }
    },
  },
  methods: {
    ...mapActions({
      errorMessage: 'message/errorMessage',
      successMessage: 'message/successMessage',
      
      createFullMortgageDeal: 'mortgageDeal/createFullMortgageDeal',
      fetchBasicList: 'mortgageDeal/fetchBasicList',
      
      initValidStore: 'valid/initStore',
    }),
    createCompleteMortgageDeal (data) {
      const payload = {
        [TYPE.MORTGAGE_DEAL]: {
          ...this.apiCallMetadata,
          [PROPERTY.MORTGAGE_DEAL_ID]: data[PROPERTY.MORTGAGE_DEAL_ID],
          [PROPERTY.MORTGAGE_ID]: data[PROPERTY.MORTGAGE_ID],
          [PROPERTY.STATUS]: data[PROPERTY.STATUS],
          [PROPERTY.PROGRAM]: data[PROPERTY.PROGRAM],
          [PROPERTY.TERM_START_DATE]: data[PROPERTY.TERM_START_DATE],
          [PROPERTY.TERM_END_DATE]: data[PROPERTY.TERM_END_DATE],
          [PROPERTY.TERM_LENGTH_YEARS]: data[PROPERTY.TERM_LENGTH_YEARS],
          [PROPERTY.FEE]: data[PROPERTY.FEE],
          [PROPERTY.DATE_PAID]: data[PROPERTY.DATE_PAID].length === 0 ? null : data[PROPERTY.DATE_PAID],
          [PROPERTY.LANGUAGE]: data[PROPERTY.LANGUAGE],
          [PROPERTY.REFERRAL_COMPANY]: data[PROPERTY.REFERRAL_COMPANY].length === 0 ? null : data[PROPERTY.REFERRAL_COMPANY],
          [PROPERTY.DATE_CREATED]: data[PROPERTY.DATE_CREATED],
        },
        [TYPE.MORTGAGE_DEAL_PROGRAM_CONTROL]: {
          ...this.apiCallMetadata,
          [PROPERTY.MORTGAGE_DEAL_ID]: data[PROPERTY.MORTGAGE_DEAL_ID],
          [PROPERTY.BATCH]: data[PROPERTY.BATCH],
          [PROPERTY.SENT_TYC]: 'N',
          [PROPERTY.SENT_ML]: 'N',
          [PROPERTY.SUPPRESS_TYC]: 'N',
          [PROPERTY.SUPPRESS_ML]: 'N',
          [PROPERTY.SUPPRESS_BA]: 'N',
          [PROPERTY.SUPPRESS_MAG]: 'N',
          [PROPERTY.SUPPRESS_CAL]: 'N',
          [PROPERTY.SUPPRESS_BD]: 'N',
          [PROPERTY.INCLUDE_CALENDAR]: 'N',
          [PROPERTY.HOLD]: 'N',
        },
        [TYPE.MORTGAGE]: {
          ...this.apiCallMetadata,
          [PROPERTY.MORTGAGE_ID]: data[PROPERTY.MORTGAGE_ID],
          // This appendUUID is incase the user deletes the provided loan_code and attempts to send a null loan_code to the backend.
          [PROPERTY.LOAN_CODE]: data[PROPERTY.LOAN_CODE].length === 0 ? appendUUID('NEW_') : data[PROPERTY.LOAN_CODE],
          [PROPERTY.CLOSING_DATE]: data[PROPERTY.CLOSING_DATE].length === 0 ? null : data[PROPERTY.CLOSING_DATE],
          [PROPERTY.MATURITY_DATE]: data[PROPERTY.MATURITY_DATE].length === 0 ? null : data[PROPERTY.MATURITY_DATE],
        },
        [TYPE.MORTGAGE_PROPERTY]: {
          ...this.apiCallMetadata,
          [PROPERTY.MORTGAGE_ID]: data[PROPERTY.MORTGAGE_ID],
          [PROPERTY.ADDRESS]: data[PROPERTY.ADDRESS],
          [PROPERTY.CITY]: data[PROPERTY.CITY],
          [PROPERTY.POSTAL_CODE]: data[PROPERTY.POSTAL_CODE],
          [PROPERTY.PROVINCE]: data[PROPERTY.PROVINCE],
          'country': 'CA',
        },
      }

      this.dialog.saving = true

      this.createFullMortgageDeal({ payload })
        .then(() => {
          this.refreshEntries()
          this.dialog.show = false
          this.successMessage(`Successfully added the mortgage deal to the list.`)
        })
        .catch(error => {
          this.errorMessage(`Failed to add the mortgage deal to the list. ${transformError(error)}`)
        })
        .finally(() => {
          this.dialog.saving = false
        })
    },
    /**
     * Close and reset a dialog window.
     */
    closeDialog () {
      this.dialog = { show: false, config: {}, payload: {}, saving: false, }
    },
    initTemplateConstants() {
      this.DEFAULT_TEXT = TEXT_COLOR.DEFAULT
      this.MORTGAGE_DEALS_VUE = VUES.MORTGAGE_DEALS
      this.MORTGAGE_DEAL_ICON = ICONS.MORTGAGE_DEAL
      this.SEARCH_ICON = ICONS.SEARCH
    },
    /**
     * Initializes and opens the dialog window for this data-type with default values set for the fields.
     */
    openDialog () {
      // We only want to include the batch number for this mortgage deal.
      const programControlFieldSubset = this.mortgageDeal.getFieldGroups(TYPE.MORTGAGE_DEAL_PROGRAM_CONTROL)[0].filter(field => field.property === 'batch')

      this.dialog = {
        show: true,
        config: {
          title: 'Create Mortgage Deal',
          fields: {
            fieldGroups: flatten([
              cloneDeep(this.mortgageDeal.getFieldGroups(TYPE.MORTGAGE_DEAL)),
              [programControlFieldSubset],
              cloneDeep(this.mortgageDeal.getFieldGroups(TYPE.MORTGAGE)),
              cloneDeep(this.mortgageDeal.getFieldGroups(TYPE.MORTGAGE_PROPERTY)),
            ]),
            fieldGroupTitles: ['Mortgage Deal', 'Program Controls', 'Mortgage', 'Mortgage Property',]
          },
        },
        payload: {
          ...cloneDeep(this.mortgageDeal.getDefault(TYPE.MORTGAGE_DEAL)),
          ...cloneDeep(this.mortgageDeal.getDefault(TYPE.MORTGAGE)),
          ...cloneDeep(this.mortgageDeal.getDefault(TYPE.MORTGAGE_PROPERTY)),
        },
        saving: false,
      }

      this.dialog.payload.loan_code = appendUUID('NEW_')

      delete this.dialog.payload.default
    },
    refreshEntries (force = true) {
      this.loading = true

      this.fetchBasicList({ force })
        .then(basicMortgageDeals => {
          this.mortgageDeals = basicMortgageDeals
        })
        .catch(error => {
          this.errorMessage(`Failed to load mortgage deal list. ${transformError(error)}`)
        })
        .finally(() => {
          this.loading = false
        })
    },
    viewMortgageDeal(event) {
      push(`${ROUTES.MORTGAGE_DEALS}/${event.mortgage_deal_id}`)
    },
  },
  async created () {
    this.initTemplateConstants()
    
    try{
      await this.initValidStore()
      setSelectValidData({
        provinces: this.getValidData(VALID_TABLES.PROVINCE),
        statuses: this.getValidData(VALID_TABLES.MORTGAGE_DEAL_STATUS),
        programs: this.getValidData(VALID_TABLES.PROGRAM),
        languages: this.getValidData(VALID_TABLES.LANGUAGE),
      })
      this.refreshEntries(false)
    }
    catch(error) {
      this.errorMessage(`Failed to initialize valid store. ${transformError(error)}`)
    }
  },
}
</script>

<style scoped>
button {
  outline: none;
}
#mortgage-deal-table {
  cursor: pointer;
}
</style>
